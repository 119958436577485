import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { AuthData } from "api/resources/users/types"
import AppleIcon from "assets/icons/apple-icon.svg"
import FacebookIcon from "assets/icons/facebook-circle-icon.svg"
import GoogleIcon from "assets/icons/google-icon.svg"
import AsyncBuilder from "components/AsyncBuilder"
import { useFeatureToggle } from "components/FeatureToggleProvider"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { useEffect, useState } from "react"
import { Platforms, Product } from "utils/constants"
import { getProductName, sleep } from "utils/helpers"
import toast from "utils/toast"

import {
  appleAuthHandler,
  facebookAuthHandler,
  getFirebaseAuth,
  googleAuthHandler,
} from "./helpers/firebase"

const SignupConfirmationDialog = ({
  handleClose,
  onConfirm,
}: {
  handleClose: () => void
  onConfirm: () => Promise<any>
}) => {
  return (
    <Dialog open={true} onRequestClose={handleClose} width="md">
      <DialogTitle>
        <Typography variant="title3">Sign Up?</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body">
          Looks like you don&apos;t have an account with Suraasa. Would you like
          to create one?
        </Typography>
      </DialogContent>

      <DialogFooter>
        <div className="text-right">
          <AsyncBuilder
            handler={onConfirm}
            render={({ onClick, loading }) => (
              <Button onClick={onClick} loading={loading}>
                Create my account
              </Button>
            )}
          />
        </div>
      </DialogFooter>
    </Dialog>
  )
}

type Props = {
  onAuthSuccess: (data: Omit<AuthData, "platform">) => Promise<void>
  platform: Platforms
  product: Product
  purpose: Purpose
  setLoading: (loading: boolean) => void
}

export type SocialAuthProvider = "google" | "facebook" | "apple"

type Purpose = "login" | "sign_up"

const IconNameMap = {
  google: {
    icon: GoogleIcon,
    text: "Google",
  },
  facebook: {
    icon: FacebookIcon,
    text: "Facebook",
  },
  apple: {
    icon: AppleIcon,
    text: "Apple",
  },
}

const SocialAuth = ({
  onAuthSuccess,
  platform,
  product,
  purpose = "login",
  setLoading: setSocialAuthLoading,
}: Props) => {
  const [showSignupConfirmation, setShowSignupConfirmation] = useState(false)
  const featureToggle = useFeatureToggle()

  const socialAuthEnabled = featureToggle.socialAuth.isEnabled
  const allowedProviders = featureToggle.socialAuth.providers

  const [signupData, setSignupData] = useState<{
    provider: SocialAuthProvider
    accessToken: string
    fullName?: string
  } | null>(null)

  const isSignup = purpose === "sign_up"

  const [authReady, setAuthReady] = useState(false)
  const [loading, setLoading] = useState(false)

  const startLoading = () => {
    setLoading(true)
    setSocialAuthLoading(true)
  }
  const stopLoading = () => {
    setLoading(false)
    setSocialAuthLoading(false)
  }

  const getTokenAndName = async (provider: SocialAuthProvider) => {
    if (!authReady) {
      return
    }

    const signInHandler = (() => {
      switch (provider) {
        case "google":
          return googleAuthHandler
        case "facebook":
          return facebookAuthHandler
        case "apple":
          return appleAuthHandler
      }
    })()

    const result = await signInHandler()

    if (result === null) {
      toast.error("Error signing in.")
      stopLoading()
      return
    }
    if (!result.accessToken) {
      toast.error("Error signing in.")
      stopLoading()
    }

    let fullName: string | null = ""

    // Apple only sends the full name on first sign in
    if (provider === "apple" && isSignup) {
      if (result.user.displayName) {
        fullName = result.user.displayName
      } else {
        // This allows the social auth popup to close before the prompt appears
        await sleep(500)

        fullName = window.prompt("Please enter your full name")
        if (!fullName) {
          toast.error("Full Name is required")
          stopLoading()
          return
        }
      }
    }

    return {
      fullName,
      accessToken: result.accessToken,
    }
  }

  const triggerSocialAuth = async (
    provider: SocialAuthProvider,
    config?: {
      accessToken: string
      fullName?: string
      purposeOverride?: Purpose
    }
  ) => {
    startLoading()

    const socialConfig = config ?? (await getTokenAndName(provider))
    // Full name is only needed in case of Apple
    const fullName =
      isSignup && provider === "apple"
        ? config?.fullName ?? socialConfig?.fullName
        : ""

    if (!socialConfig) {
      throw new Error("Social config is missing")
    }
    if (!socialConfig.accessToken) {
      throw new Error("Access token is missing")
    }

    const postData = {
      platform,
      tokenType: "universal",
      accountType: getProductName(product),
      purpose: config?.purposeOverride ?? purpose,
      accessToken: socialConfig.accessToken,
      ...(fullName ? { fullName } : {}),
      provider: provider,
    }

    const res = await api.users.socialAuth({
      data: postData,
    })

    if (res.isSuccessful) {
      await onAuthSuccess(res.data)
    } else {
      const fieldErrors: any = res.errors.fieldErrors
      if (fieldErrors) {
        if (fieldErrors.authorized === false) {
          toast.error("Something went wrong. Please try again.")
          stopLoading()
          return
        }
        if (fieldErrors.emailExists === false) {
          setShowSignupConfirmation(true)
          setSignupData({
            accessToken: postData.accessToken,
            provider: postData.provider,
            fullName: postData.fullName,
          })
          stopLoading()
          return
        }
      }
      if (res.errors.message) {
        toast.error(res.errors.message)
      }
    }

    stopLoading()
  }

  useEffect(() => {
    getFirebaseAuth()
      .authStateReady()
      .then(() => {
        setAuthReady(true)
      })
  }, [])

  const confirmSignup = async () => {
    if (!signupData) {
      toast.error("Error signing up. Please refresh the page and try again")
      return
    }

    await triggerSocialAuth(signupData.provider, {
      ...signupData,
      purposeOverride: "sign_up",
    })
  }

  if (!socialAuthEnabled) {
    return null
  }

  const providersCount = allowedProviders.length

  return (
    <div className="relative flex flex-col gap-2">
      {loading && <LoadingOverlay />}
      {showSignupConfirmation && (
        <SignupConfirmationDialog
          handleClose={() => {
            setShowSignupConfirmation(false)
            setSignupData(null)
            stopLoading()
          }}
          onConfirm={confirmSignup}
        />
      )}
      {providersCount !== 2 && (
        <Button
          variant="outlined"
          fullWidth
          onClick={() => triggerSocialAuth(allowedProviders[0])}
          disabled={loading}
          className="!border-onSurface-400 !text-onSurface-800"
        >
          <div className="flex w-full items-center justify-center gap-1">
            <img
              src={IconNameMap[allowedProviders[0]].icon}
              alt=""
              className="h-[20px] w-[20px]"
            />
            <Typography variant="button">
              Continue with {IconNameMap[allowedProviders[0]].text}
            </Typography>
          </div>
        </Button>
      )}
      <div className="flex flex-col gap-2 sm:flex-row">
        {allowedProviders
          .slice(providersCount === 2 ? 0 : 1)
          .map((provider, key) => (
            <Button
              variant="outlined"
              fullWidth
              onClick={() => triggerSocialAuth(provider)}
              disabled={loading}
              className="!border-onSurface-400 !text-onSurface-800"
              key={key}
            >
              <div className="flex w-full items-center justify-center gap-1">
                <img
                  src={IconNameMap[provider].icon}
                  alt=""
                  className="h-[20px] w-[20px]"
                />
                <Typography variant="button">
                  {IconNameMap[provider].text}
                </Typography>
              </div>
            </Button>
          ))}
      </div>
    </div>
  )
}

export default SocialAuth
