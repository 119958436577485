import api from "api"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"
import {
  clearAuthInfo,
  getAuthInfo,
  getCurrentPlatform,
  isValidOrigin,
} from "utils/auth"
import { Platforms } from "utils/constants"
import { routes } from "utils/routes"

type Props = {
  shouldNavigate: boolean
}

const useLogout = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()

  const { platform } = getCurrentPlatform()

  const navigate = useNavigate()

  const logout = useCallback(
    async (windowOrigin?: string) => {
      const origin = searchParams.get("origin") || ""

      const params = new URLSearchParams()
      if (platform) params.append("platform", platform)
      if (origin) params.append("redirect-url", origin)
      let url = `${routes.login}?${params.toString()}`

      if (platform === Platforms.svp) {
        url = `${routes.svp.login}?${params.toString()}`
      }

      if (platform === Platforms.applicationFormAdmin) {
        url = `${routes.internal.login}?${params.toString()}`
      }

      if (getAuthInfo(platform)) {
        await api.users.logout()
        if (windowOrigin) {
          window.parent.postMessage({ logoutSuccess: true }, windowOrigin)
        }
      }
      clearAuthInfo(platform)
      setLoading(false)

      if (props?.shouldNavigate) {
        navigate(url, { replace: true })
      }
    },
    [navigate, searchParams, props]
  )

  return { isLoading: loading, logout }
}

export const useLogoutMessaging = () => {
  const { logout } = useLogout({ shouldNavigate: false })

  useEffect(() => {
    const listener = (event: MessageEvent<any>) => {
      if (isValidOrigin(event.origin) && event.data?.logout) {
        logout(event.origin)
      }
    }
    window.addEventListener("message", listener, false)
    return () => {
      window.removeEventListener("message", listener)
    }
  }, [logout])
}

export default useLogout
