import { User } from "api/resources/users/types"
import { SocialAuthProvider } from "components/auth/SocialAuth"
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
} from "firebase/firestore"
import { buildUserName } from "utils/helpers"

import { firebaseApp } from "./init"

export const db = getFirestore(firebaseApp)

enum CollectionName {
  signupTracking = "signupTracking",
  featureToggles = "featureToggles",
}

export const trackSignupSource = async (user: User, source: string) => {
  const docRef = doc(db, CollectionName.signupTracking, user.email)

  const data = {
    email: user.email,
    name: buildUserName({
      firstName: user.firstName,
      lastName: user.lastName,
    }),
    uuid: user.uuid,
    source,
  }
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    console.info("User already exists, returning", data)
    return
  }
  console.info("Tracking signup source on Firebase", data)
  setDoc(docRef, { data })
  return null
}

export const getFeatureToggleConfig = async () => {
  const featureToggleCollection = collection(db, CollectionName.featureToggles)
  const docs = await getDocs(featureToggleCollection)

  const data: any = {}
  docs.forEach(doc => {
    data[doc.id] = doc.data()
  })
  return data as FeatureToggleConfig
}

export const SOCIAL_AUTH_FIREBASE_KEY = "socialAccountAuthentication"

type FeatureToggleConfig = {
  [SOCIAL_AUTH_FIREBASE_KEY]: {
    enabled: {
      web: boolean
      app: boolean
    }
    providers: SocialAuthProvider[]
  }
}
